import { ROLES_API } from "../resources/endPoint";

const getRolesData = () => {
  return {
    url: ROLES_API,

    method: "get",
    //   tag: ApiTag.CSRF_TOKEN,
    headers: { "content-type": "application/json" },
    authRequired: false,
    csrfToken: true,
  };
};
export default getRolesData;
