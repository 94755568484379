import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setProjects,
  setSelectedProject,
  setOpenProjectDialogForm as setOpenDialog,
  setProjectDeleteSuccess,
  setIsLoading,
  setUsers,
} from "../../../State/actions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import { fetchUsers } from "../UserManagementPage/UserManagementPage";

import {
  GridActionsCellItem,
  // GridRowEditStopReasons,
} from "@mui/x-data-grid";

import { toast } from "react-toastify";

import "./ProjectManagementPage.css";

import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
const { REACT_APP_API_BASE_URL } = process.env;

import getProjectsData from "../../../Api/request/projects";

const projectsApi = new AxiosWrapper(REACT_APP_API_BASE_URL);

const handleRowEdit = (id, row) => () => {
  console.log("handleRowEdit :" + id);
  setSelectedProject(row);
  setOpenDialog(true);
};

const handleRowDelete = (id) => async () => {
  console.log("handleRowDelete:" + id);
  await deleteProject(id);
};

const handleFormSubmit
  = (selectedProject, projectsState, setProjectsState) => () => {
    console.log(selectedProject);
    if (!selectedProject.id) {
      createProject(selectedProject, projectsState, setProjectsState);
    } else {
      // Edit User
      updateProject(selectedProject, projectsState, setProjectsState);
    }
    setSelectedProject({ name: "", vertical: "", status: "" });
  };

const fetchData = async (setProjectsState, setUsersState) => {
  try {
    setIsLoading(true);

    await fetchUsers(setUsersState);
    await fetchProjects(setProjectsState);

    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const columnsArr = [
  { field: "id", headerName: "ID", width: 200 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "vertical", headerName: "Vertical", width: 150 },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 200,
    cellClassName: "actions",
    getActions: ({ id, row }) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleRowEdit(id, row)}
          color="inherit"
          key="edit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleRowDelete(id)}
          color="inherit"
          key="delete"
        />,
      ];
    },
  },
];

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    id: "vertical",
    label: "Vertical",
    type: "select",
    selectedValue: "LEAD",
    options: [
      { value: "App Mod", label: "App Mod" },
      { value: "Data Mod", label: "Data Mod" },
      { value: "Infra Mod", label: "Infra Mod" },
    ],
  },

  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
    ],
  },

  {
    id: "jenkinsUrl",
    label: "Jenkins Base Url",
    type: "text",
    required: true,
  },
  {
    id: "jenkinsUsername",
    label: "Jenkins Username",
    type: "text",
    required: true,
  },
  {
    id: "jenkinsToken",
    label: "Jenkins Token",
    type: "text",
    required: true,
  },
  {
    id: "approversList",
    label: "Approvers List",
    type: "select",
    isMultiple: true,
    selectedValue: "",
    options: [],
  },
];

export const fetchProjects = async (setProjectsState) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    const response = await projectsApi.get(projectsRequestData.url);
    const projects = response?.data?.data?.projects;
    setProjectsState(projects);
    toast.success(response?.data?.message || "Projects fetched successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const createProject = async (
  selectedProject,
  projectsState,
  setProjectsState
) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    const response = await projectsApi.post(
      projectsRequestData.url,
      selectedProject
    );
    const project = response?.data?.data?.project;
    setProjectsState([...projectsState, project]);
    toast.success(response?.data?.message || "Project created successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const updateProject = async (
  selectedProject,
  projectsState,
  setProjectsState
) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    const id = selectedProject?.id;
    const response = await projectsApi.put(
      `${projectsRequestData.url}/${id}`,
      selectedProject
    );
    const project = response?.data?.data?.project;
    const newState = projectsState?.map((projectState) => {
      if (projectState.id === selectedProject.id) return project;
      else return projectState;
    });
    setProjectsState(newState);
    toast.success(response?.data?.message || "Project updated successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const deleteProject = async (id) => {
  try {
    const isconfirmed = confirm(
      "Are you sure you want to delete the project with id: " + id
    );
    if (!isconfirmed) return;
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    await projectsApi.delete(`${projectsRequestData.url}/${id}`);

    setProjectDeleteSuccess(id);
    toast.success("Project deleted successfully");
    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);

    toast.error(error?.response?.date?.data?.message || error?.message);
    setIsLoading(false);
  }
};

const paginationModel = { pcreatedDate: 0, pcreatedDateSize: 50 };

const ProjectMancreatedDatement = () => {
  const projectsState = useSelector((state) => state?.project?.projects);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(columnsArr);
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const openDialog = useSelector((state) => state?.project?.openDialog);
  const usersState = useSelector((state) => state?.user?.users);
  const [formInputs, setFormInputs] = useState(dialogFormInputs);

  useEffect(() => {
    fetchData(setProjects, setUsers);
  }, []);

  useEffect(() => {
    const userOptions = usersState?.map((user) => {
      return { value: user?.id, label: user?.email };
    });
    const dialogFormInputsLength = dialogFormInputs.length;
    dialogFormInputs[dialogFormInputsLength - 1].options = userOptions;

    setFormInputs(dialogFormInputs);
  }, [usersState]);

  useEffect(() => {
    setRows(projectsState);
  }, [projectsState]);

  return (
    <div className="project-mancreatedDatement-container">
      <DataTable
        rows={rows}
        columns={columns}
        setColumns={setColumns}
        paginationModel={paginationModel}
        pageSizeOptions={[50, 100, 150]}
        opendialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={formInputs}
        formState={selectedProject}
        setFormState={setSelectedProject}
        handleFormSubmit={handleFormSubmit(
          selectedProject,
          projectsState,
          setProjects
        )}
        enableAddRecord={true}
      />
    </div>
  );
};

export default ProjectMancreatedDatement;
