import React, { useEffect } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

import { GoogleLogin } from "@react-oauth/google";
import LeftPanel from "./Left Panel.svg";
import "./login.css";

function LogInPage() {
  const { handleSuccessLogin, handleErrorLogin, loggedIn, checkLogin }
  = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (loggedIn) navigate("/admin-panel");
  }, [loggedIn]);

  return (
    <div className="login-container">
      <div className="login-left-panel">
        <div className="overlay">
          <h1 className="dmanager-title">*DManager</h1>
        </div>
        <img src={LeftPanel} alt="Illustration" className="login-illustration" />
      </div>
      <div className="login-right-panel">
        <div className="login-form-container">
          <h2>Welcome Back!</h2>
          <p>Sign in with Google to get started effortlessly</p>
          <div className="divider">
            <span>continue with</span>
          </div>
          <div className="login-btn">
          <GoogleLogin
            onSuccess={async (response) => {
              await handleSuccessLogin(response);
              navigate("/admin-panel");
            }}
            onError={handleErrorLogin}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

LogInPage.propTypes = {
  // anchorEl: PropTypes.object,
  // handleClose: PropTypes.func.isRequired,
  // changeLanguage: PropTypes.func.isRequired,
};

export default LogInPage;
