import React from "react";

import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FormDialog from "../FormDialog/FormDialog";

import { GridToolbarContainer, DataGrid } from "@mui/x-data-grid";

function EditToolbar({ setOpenDialog }) {
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpenDialog(true)}
        sx={{ width: "200px" }}
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setOpenDialog: PropTypes.func,
};

/* eslint-disable max-lines-per-function */
export default function DataTable({
  rows,
  columns,
  paginationModel,
  pageSizeOptions,
  formInputs,
  dialogTitle,
  dialogcontentText,
  formState,
  setFormState,
  handleFormSubmit,
  enableAddRecord,
  opendialog,
  setOpenDialog,
}) {
  // const [opendialog, setOpenDialog] = useState(false);

  return (
    <Paper sx={{ height: 456, width: "100%" }}>
      <FormDialog
        open={opendialog}
        setOpen={setOpenDialog}
        formInputs={formInputs}
        title={dialogTitle}
        contentText={dialogcontentText}
        formState={formState}
        setFormState={setFormState}
        handleFormSubmit={handleFormSubmit}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={(params) => {
          console.log(params);
          setFormState(params.row);
          setOpenDialog(true);
        }}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={pageSizeOptions}
        // checkboxSelection
        sx={{ border: 0 }}
        slots={{
          toolbar: enableAddRecord ? EditToolbar : null,
        }}
        slotProps={{
          toolbar: enableAddRecord
            ? {
                setOpenDialog,
              }
            : null,
        }}
      />
    </Paper>
  );
}

DataTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  paginationModel: PropTypes.object,
  pageSizeOptions: PropTypes.array,

  opendialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  formInputs: PropTypes.array,
  dialogTitle: PropTypes.string,
  dialogcontentText: PropTypes.string,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  enableAddRecord: PropTypes.bool,
};
