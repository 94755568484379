import axios from "axios";

const DEFAULT_TIMEOUT = 30000;

class AxiosWrapper {
  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    this.setupInterceptors();
  }

  setupInterceptors() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const isLoggedIn = localStorage.getItem("isLoggedIn");
        if (error.response.status !== 401 || isLoggedIn === "false") {
          return Promise.reject(error);
        }

        return axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/login/refresh`, {
            grant_type: "refresh_token",
          })
          .then(() => {
            console.log(error?.config);

            return this.axiosInstance(error?.config);
          })
          .catch(() => {
            localStorage.setItem("isLoggedIn", "false");
            const origin = window.location.origin;
            const url = `${origin}/signin`;
            window.location.href = url;

            return Promise.reject(error);
          });
      }
    );
  }

  get(url, params = {}) {
    return this.axiosInstance.get(url, { params });
  }

  post(url, data = {}) {
    return this.axiosInstance.post(url, data);
  }

  put(url, data = {}) {
    return this.axiosInstance.put(url, data);
  }

  delete(url, params = {}) {
    return this.axiosInstance.delete(url, { params });
  }
}

export default AxiosWrapper;
