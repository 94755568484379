// src/State/reducers.js
// import { SET_USER } from "./actions";

const loadingReducer = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};

const authReducer = (
  state = { loggedInUser: null, authSuccessRedirect: "" },
  action
) => {
  switch (action.type) {
    case "SET_AUTH_USER":
      return { ...state, loggedInUser: action.payload };
    case "SET_AUTH_SUCCESS_REDIRECT":
      return { ...state, authSuccessRedirect: action.payload };

    default:
      return state;
  }
};

const userReducer = (
  state = {
    users: [],
    selectedUser: { name: "", title: "", status: "", email: "" },
    openDialog: false,
  },
  action
) => {
  switch (action.type) {
    case "SET_USERS":
      return { ...state, users: action.payload };

    case "SET_SELECTED_USER":
      return { ...state, selectedUser: action.payload };
    case "SET_OPEN_USER_DIALOG_FORM":
      return { ...state, openDialog: action.payload };
    case "SET_USER_DELETE_SUCCESS": {
      const users = state?.users;
      const newUsers = users.filter((user) => user.id !== action.payload);

      return { ...state, users: newUsers };
    }

    default:
      return state;
  }
};

const projectReducer = (
  state = {
    projects: [],
    selectedProject: {
      name: "",
      vertical: "",
      status: "",
      jenkinsUrl: "",
      jenkinsUsername: "",
      jenkinsToken: "",
      approversList: [],
    },
    openDialog: false,
  },
  action
) => {
  switch (action.type) {
    case "SET_PROJECTS":
      return { ...state, projects: action.payload };
    case "SET_SELECTED_PROJECT":
      return { ...state, selectedProject: action.payload };
    case "SET_OPEN_PROJECT_DIALOG_FORM":
      return { ...state, openDialog: action.payload };
    case "SET_PROJECT_DELETE_SUCCESS": {
      const projects = state?.projects;
      const newProjects = projects.filter(
        (project) => project.id !== action.payload
      );

      return { ...state, projects: newProjects };
    }

    default:
      return state;
  }
};

const pipelineReducer = (
  state = {
    pipelines: [],
    selectedPipeline: {
      name: "",
      environment: "",
      projectId: "",
      approverId: [],
      status: "",
      url: "",
    },
    selectedProjectId: "",
    openDialog: false,
  },
  action
) => {
  switch (action.type) {
    case "SET_PIPELINES":
      return { ...state, pipelines: action.payload };
    case "SET_SELECTED_PIPELINE":
      return {
        ...state,
        selectedPipeline: action.payload,
        selectedProjectId: action?.payload?.projectId,
      };

    case "SET_OPEN_PIPELINE_DIALOG_FORM":
      return { ...state, openDialog: action.payload };
    case "SET_PIPELINE_DELETE_SUCCESS": {
      const pipelines = state?.pipelines;
      const newPipelines = pipelines.filter(
        (pipeline) => pipeline.id !== action.payload
      );

      return { ...state, pipelines: newPipelines };
    }

    default:
      return state;
  }
};

const rolesReducer = (state = { roles: [] }, action) => {
  switch (action.type) {
    case "SET_ROLES":
      return { ...state, roles: action.payload };

    default:
      return state;
  }
};

export {
  authReducer,
  userReducer,
  projectReducer,
  pipelineReducer,
  loadingReducer,
  rolesReducer,
};
